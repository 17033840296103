import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "../storage/storage.service";
import { environment } from "../../../../src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PointsService {
  userInfo: any;
  uriApiPoints: string = environment.gluky.pointsApiBasePath;

  constructor(
    private http: HttpClient,
    private storageServices: StorageService
  ) {}

  async getUserPoints() {
    const claims = await this.storageServices.get("gnx-claims");
    //console.log("getUserPoints CLAIMS USER: ",claims, claims.uid, claims.app_id);
    const typeAccount: any = await this.getUserTypeAccount();
    if (typeAccount && typeAccount.points_account_type) {
      const type = typeAccount.points_account_type;
      //console.log("typo2",typeAccount);
      return new Promise((resolve, reject) =>
        this.http
          .get(
            `${this.uriApiPoints}/frontend/users/${claims.uid}/accounts?type=${type}`
          )
          .subscribe(
            async (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
      );
    } else return false;
  }

  async getUserHistoryPoints(accountId) {
    return new Promise((resolve, reject) =>
      this.http
        .get(`${this.uriApiPoints}/frontend/accounts/${accountId}/transactions`)
        .subscribe(
          async (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async getUserTypeAccount() {
    const claims = await this.storageServices.get("gnx-claims");
    //console.log("getUserPoints CLAIMS USER: ", claims.programs[0]);
    if (claims && claims.programs && claims.programs[0]) {
      return new Promise((resolve, reject) =>
        this.http
          .get(
            `${environment.gluky.usersApiBasePath}/frontend/${claims.programs[0]}`
          )
          .subscribe(
            async (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
      );
    } else return false;
  }

  async historyCredentials() {
    const userInfo: any = await this.getUserPoints();
    console.log(userInfo);
    const tokenU = await this.storageServices.get("gnx-token");
    const accountId = userInfo && userInfo.data.length > 0 && userInfo.data[0].id
        ? userInfo.data[0].id
        : "";
    const apikey = environment.gluky.apiKeyWeb;

    if (accountId === "") {
      return {
        base_url: null,
        api_key: apikey,
        token: tokenU,
      };
    } else {
      return {
        base_url: `${this.uriApiPoints}/frontend/accounts/${accountId}/transactions`,
        api_key: apikey,
        token: tokenU,
        accountId
      };
    }
  }
}
