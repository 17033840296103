import { Component, OnInit } from '@angular/core';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { RouterService } from 'src/app/services/router/router.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  phoneContact: any;
  apiWhatsapp: string = "https://api.whatsapp.com/send?phone=";
  licenseId: string;
  
  constructor(private remoteConfig: RemoteConfigService,
    private storageService: StorageService,
    private routerService: RouterService) { }

  async ngOnInit() {
    await this.remoteConfig.getInfoApp().toPromise().then(( res:any ) => {
      console.log("++",res);
      this.phoneContact = res["phone-contact"];
    } );

    this.apiWhatsapp = `${this.apiWhatsapp}${this.phoneContact}&text=¡Hola!`;

    var licenseId = await this.storageService.get('license-id');
    if (licenseId && licenseId.length > 0) {
      this.licenseId = licenseId;
    } else {
      this.remoteConfig.getInfoApp().subscribe((data: any) => {
        if (data['license-id']) {
          this.licenseId = data['license-id'];
          this.storageService.set('license-id', this.licenseId);
        }
      });
    }
  }

  openChat(){
      window.location.href = this.apiWhatsapp;
    }
  

}
