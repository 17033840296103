import { Component, Input, OnInit } from '@angular/core';
import { InvoicesService } from 'src/app/services/invoices/invoices.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-invoice-detail-component',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent implements OnInit {
  CurrencyCode
  item;
  isLinear = false;
  @Input() id

  constructor(private invoicesService: InvoicesService, private userService : UserService) { }

  ngOnInit() {
    this.getDataDocument()
  }

  getDataDocument() {
    this.invoicesService.getDetail(this.id).then((data: any ) => {
      console.log(data);     
      this.item = data;
    })
  }
  getCurrency(){
    this.userService.getCurrencyCodeProgram().then((data)=>{
      this.CurrencyCode = data
    })
  }
}