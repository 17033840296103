import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RouterService } from '../router/router.service';
import { StorageService } from '../storage/storage.service';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: RouterService,
    private networkService: ConnectivityService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.storageService.get('gnx-token').then(async (res) => {
      const sizeWidth = document.body.clientWidth;
      if (res || sizeWidth > 576) {
        this.networkService
          .getNetworkStatus()
          .subscribe((connected: boolean) => {
            if (connected) {
              this.router.redirect('/app/home');
            } else {
              this.router.redirect('/app/exhibitions');
            }
          });
        return false;
      }
      return true;
    });
  }
}
