import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../storage/storage.service';
import moment from 'moment';
import { AlertService } from '../alert/alert.service';
import { ErrorResponseText } from './ErrorResponseText';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService {
  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(
    private firestore: AngularFirestore,
    private storageServices: StorageService,
    private injector: Injector
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    const date = new Date();
    (async () => {
      const claims = await this.storageServices.get('gnx-claims');
      const ERROR_REFINED = { ...error };
      ERROR_REFINED['date'] = date;
      ERROR_REFINED['claims'] = claims;
      ERROR_REFINED['type'] = 'Client Error';
      if (error instanceof HttpErrorResponse) {
        // Server Error
        console.error('HttpErrorResponse handleError Server -> ', date, error);
        ERROR_REFINED['type'] = 'Server Error';
        delete ERROR_REFINED['headers'];

        if (Object.keys(ErrorResponseText).includes(error.status.toString()))
          this.alertError(`${ErrorResponseText[error.status]} - ${error.url}`);
      } else {
        // Client Error
        delete ERROR_REFINED['promise'];
        delete ERROR_REFINED['task'];
        delete ERROR_REFINED['zone'];
        ERROR_REFINED['stask'] = error.stack;
        console.error('HttpErrorResponse handleError Client -> ', date, error);
        return;
      }

      // Always log errors
      await this.setLogger(ERROR_REFINED);
    })();
  }

  async setLogger(data) {
    console.debug('setLogger data -> ', data);
    const docName = `${moment(data.date).format('YYYYMMDD')}`;
    const ref = await this.firestore
      .collection('log-errors')
      .doc(docName)
      .collection(`${data.date.valueOf()}`)
      .doc(`${data.date.valueOf()}`)
      .set(data);
  }

  alertError(mensaje) {
    const alertError = this.injector.get(AlertService);
    (async () => {
      await alertError.presentOrderStatus(
        mensaje,
        'close-circle-outline',
        'gnx-icon-order-status-error'
      );
    })();
  }
}
