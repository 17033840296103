
import { StorageService } from 'src/app/services/storage/storage.service';
import { PushNotificationsManagerService } from './../../components/push-notifications-manager/push-notifications-manager.service';
import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';
import { Router } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../auth/auth.service";
import firebase from "firebase/app";
import { first ,map } from "rxjs/operators";
import { MessagingService } from "src/app/services/messaging/messaging.service";

const { PushNotifications } = Plugins;
export const KEY_THIS_TOKEN_FCM_DISPOSITIVE = 'KEY_THIS_TOKEN_FCM_DISPOSITIVE'
 
@Injectable({
  providedIn: 'root'
})
export class FcmService {
  UserInfoUser$
  public tokenFcm: any;
  constructor(private router: Router,
              private firestoreA: AngularFirestore,
              private authService: AuthService,
              private messagingService:MessagingService,
              private storageService : StorageService,
              private pushNotificationsManagerService : PushNotificationsManagerService) { }
 
  initPush() {
    if (Capacitor.platform !== 'web') {
      this.registerPush();
    } else {
      this.messagingService.requestPermission().subscribe(async token => {
        console.log("set token",token);
        await this.setTokenStorage(token)
        this.setToken(token);
        this.tokenFcm  = token;
      });
    }
    this.pushNotificationsManagerService.getNotifications();
  }

  async setToken(tokenfcm) {
    // console.log("setTokenPush", tokenfcm, firebase.firestore.FieldValue.arrayUnion(tokenfcm));
     console.log("setToken(tokenfcm) this.authService.userGnxId ->", this.authService.userGnxId , '<-');
    if(this.authService.userGnxId){
       this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({
        token:  firebase.firestore.FieldValue.arrayUnion(tokenfcm),
      });

    await this.getUserInfoUser().pipe(first()).subscribe(async (user: any) => {
        (await this.authService.sendFcmToken(tokenfcm,user))?.subscribe((data)=> {
          console.log('token send', data);
          
        },error =>{
          console.error(error);
          
        })
      })
    }
   
      

  }

  async setTokenStorage(token){
    const dataTokenOld = await this.storageService.get(KEY_THIS_TOKEN_FCM_DISPOSITIVE)
    if (!dataTokenOld || dataTokenOld?.token != token) {
      const dataToken = {
        token,
        time: new Date(),
        validated: false
      }
      console.log('setTokenStorage ', dataToken);
      await this.storageService.set(KEY_THIS_TOKEN_FCM_DISPOSITIVE, dataToken)
    }

  }

  getUserInfoUser() {
    const UserInfoUser$ = this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .valueChanges();
      return UserInfoUser$;

  }

  async getUserInfo(){
    return this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data: any = action.payload.data();
          data.id = action.payload.id;
          return data;
        })
      );
  }
 
  private registerPush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });
 
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('My token fcm: ' + JSON.stringify(token.value));
        this.setToken(token.value);
        this.tokenFcm  = JSON.stringify(token.value);
        console.log("this.tokenFcm dispositive", this.tokenFcm );
        this.pushNotificationsManagerService.getNotifications();

      }
    );
 
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });
 
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.pushNotificationsManagerService.getNotifications();
      }
    );
 
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.detailsId) {
          this.router.navigateByUrl(`/home/${data.detailsId}`);
        }else {
          this.router.navigateByUrl('app/home');
        }
      }
    );
  }
}