import { RouterService } from 'src/app/services/router/router.service';
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiService } from 'src/app/services/kpi/kpi.service';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

@Component({
  selector: 'app-kpi-carousel',
  templateUrl: './kpi-carousel.component.html',
  styleUrls: ['./kpi-carousel.component.scss'],
})
export class KpiCarouselComponent implements OnInit {
  sliderw = 3;
  @Input() titleCarousel: string;
  @Input() subtitleCarousel: string;
  @Input() user;
  @Output() kpiListSelected: EventEmitter<any> = new EventEmitter();
  @Output() kpiSelected: EventEmitter<any> = new EventEmitter();

  cant = 5;
  slideOpts = {
    slidesPerView: window.screen.width > 768 ? 3: 1,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 2,
      depth: 100,
      modifier: 0,
      slideShadows: true,
    },
    pagination : {
      el: '.swiper-pagination',
      type: 'bullets' ,
      clickable: true
    }
  };
  list;
  labels = []

  constructor(private kpiService: KpiService,
    private languageService: LanguagesService,
    private route : RouterService) { }

  ngOnInit() {
    this.getKpis();
  }

  slidePrev() {
    const slides = document.querySelectorAll('ion-slides')[1];
    slides.slidePrev();
  }

  slideNext() {
    const slides = document.querySelectorAll('ion-slides')[1];
    slides.slideNext();
  }

  async getKpis() {
    let list2 = await this.kpiService.getKpisUrl();
    list2.subscribe((data:any)=>{
     const  list = [...data.data]
      this.list = list ? list.sort((a, b) => (new Date(b["updatedAt"]).valueOf()   - new Date(a["updatedAt"]).valueOf()))
      .filter((data)=>{return data.visibility}): [];


      this.list.forEach((kpi: any, index) => {
        kpi.KpiInputs = [];
        if (kpi.kpi_config.inputs_config && kpi.inputs) {
          const inputsConfig = kpi.kpi_config.inputs_config;
          const inputs_config_sort: [] = inputsConfig ? inputsConfig?.sort((a, b) => (parseInt(a["position"])  - parseInt(b["position"]))) : [];
          
          inputs_config_sort.forEach((input: any, index) => {
            const object = { id: input.id, name: input.name, value: '', position: index }
            const value = kpi.inputs.find((inputValue) => { return inputValue.id == input.id })?.value

            object.value = value ? value : 0
            if (value) {
              kpi.KpiInputs.push(object)
            }


          })
        }
      })



    },error =>{
      console.log(error);
      
    })
  //  this.list = await this.kpiService.getKpis(this.user,this.cant);
    
  }

  kpiListHandler(){
    //this.kpiListSelected.emit();
    this.route.redirect('app/kpi/categories')
  }

  kpiDetail(data){
    this.kpiSelected.emit(data);
  }

  get getTextsIUPageKpi() {
    return this.languageService.textsIU.page_kpi;
  }

  kpiCategories(kpi){
    this.route.redirect('/app/kpi/categories/swALEauQkGCJLX63lVhz/indicators/indicator/'+ kpi.id)
  }


}