import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

import { catchError,  delay,  mergeMap, retryWhen, switchMap } from "rxjs/operators";
import { from, of, throwError } from "rxjs";
import { CODES_TO_RETRY, RETRIES, RETRY_WAIT_MILLI_SECONDS } from "./config-police-retry-http";


@Injectable({
  providedIn: "root",
})

export class ServerHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    /* When user get new refresh token with X-Skip-Interceptor
    this code prevent infinite loop in this interceptor
    */
    if (request.headers.get("X-Skip-Interceptor") == "") {
      return next.handle(request).pipe(
        retryWhen((error) => {
          console.log("ServerHttpInterceptor X-Skip-Interceptor retryWhen", error);

          return error.pipe(
            mergeMap((error, index) => {
              
              if (index < RETRIES && CODES_TO_RETRY.includes(error.status)) {
                return of(error).pipe(delay(RETRY_WAIT_MILLI_SECONDS));
              }

              throw error;
            })
          )
        }
        ),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // refresh token
            // TODO hacer otras validaciones
          } else {
            return throwError(error);
          }
        })
      );
    }

    return from(this.authService.refreshToken()).pipe(
      switchMap((token) => {
        let headers = {};
        /* IF TOKEN EXIST USE ON REQUEST */
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
        /* REQUEST INSIDE TABS (INSIDE APLICACION WITH USER AUTHENTICATED */
        if (request.headers.get("X-Skip-Interceptor") !== "") {
          request = request.clone({
            setParams: {
              key: this.authService.apiKey,
            },
          });
        }
        /* ADD HEADERS */
        request = request.clone({
          setHeaders: headers,
        });
        
        return next.handle(request).pipe(
          retryWhen((error) => {
            return error.pipe(
              mergeMap((error, index) => { 
                console.debug("ServerHttpInterceptor retryWhen mergeMap", error);
                if (index < RETRIES && CODES_TO_RETRY.includes(error.status) ) {
                  return of(error).pipe(delay(RETRY_WAIT_MILLI_SECONDS));
                }
                throw error;
              })
            )
          }
          )
        )
      })
    )
  }

}
