import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterService } from 'src/app/services/router/router.service';
import { ImageComponent } from '../image/image.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-item-content',
  templateUrl: './item-content.component.html',
  styleUrls: ['./item-content.component.scss'],
})
export class ItemContentComponent implements OnInit {
  @Input() content: any = {};

  constructor(
    private sanitizer: DomSanitizer,
    public routerService: RouterService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  transform(url: string) {
    url = url.includes('watch?v=') ? url.replace('watch?v=', 'embed/') : url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  transformYoutube(url) {
    url = url.includes('watch?v=') ? url.replace('watch?v=', 'embed/') : url;
    return url;
  }

  getIdYoutube(url) {
    if (url.includes('youtu.be')) {
      url = url.split('youtu.be/')[1].split('?')[0];
      return url;
    }
    const urlSplit = url.split('watch?v=')[1].split('&')[0];
    return urlSplit;
  }

  goTo(image: any) {
    if (
      image.linkType == 'EXTERNAL' ||
      image.linkType == 'url' ||
      image.linkType == 'video'
    ) {
      window.open(image.linkId, '_blank');
    } else {
      const url =
        image.linkId.charAt(0) === '/' ? image.linkId.slice(1) : image.linkId;
      this.routerService.redirect(url);
    }
  }

  async zoomImg(img) {
    const image = img.url;
    if (img.link && img.link.Type) {
      if (
        img.link.Type == 'EXTERNAL' ||
        img.link.Type == 'url' ||
        img.link.Type == 'video'
      ) {
        window.open(img.link.linkId, '_blank');
      } else {
        this.routerService.redirectRoot(img.link.linkId);
      }
      return;
    }

    const modal = await this.modalController.create({
      component: ImageComponent,
      id: 'modal-img',
      componentProps: {
        image: image,
      },
      backdropDismiss: true,
    });
    return await modal.present();
  }
}
