import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { FormGroup } from "@angular/forms";

export interface marker {
  latitude: number;
  longitude: number;
  label?: string;
  draggable?: boolean;
  animation?: 'BOUNCE' | 'DROP';
  iconUrl?: string | google.maps.Icon | google.maps.Symbol;
  opacity?: string;
  title?: string;
  zIndex?: number;
}
@Component({
  selector: "app-google-maps",
  templateUrl: "./google-maps.component.html",
  styleUrls: ["./google-maps.component.scss"],
})
export class GooglemapsComponent implements OnInit {
  mapOptions = {
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
  };

  @Output() AddressInfoPlaces = new EventEmitter<any>();
  address: string;
  newAdressForm = new FormGroup({});
  geoCodeId: string;

  geoCoder: google.maps.Geocoder;
  latitude: number = 6.2449571
  longitude: number = -75.5947741
  zoom: number = 11;

  @ViewChild("searchbar", { read: ElementRef })
  public searchElementRef: ElementRef;
  @Input() viewSearchBar = false;

  markersMap: marker[] = [];
  @Input()
  set markers(markers: marker[]) {
    if (markers.length) {
      this.markersMap = [...markers];

    }
  }

  constructor(private mapsAPILoader: MapsAPILoader, private remoteConfig: RemoteConfigService) { }

  ngOnInit() {
    this.initApiMaps();

    this.remoteConfig.getSettingConfigDefaultMap().subscribe((data) => {
      console.log('getSettingConfigDefaultMap', data);
      Object.keys(data).forEach((key) => {
        if (this[key]) {
          this[key] = data[key]
        }
      })
    })
  }


  ngOnChanges(changes) {
  }

  onMapReady(map?: google.maps.Map) {
    if (map) {
      map.setOptions({ ...this.mapOptions });
    }
  }

  initApiMaps = () => {
    this.mapsAPILoader.load().then(() => {
      this.initGeoCoder();
      this.setCurrentLocation();
      this.listenForPlaceChange();
    });
  };
  setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    });
  };
  setAddress = () => {
    const { latitude, longitude } = this;
    this.getAddress(latitude, longitude);
  };
  getAddress(latitude, longitude) {
   /*  this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.AddressInfoPlaces.emit(results[0]);
            this.address = results[0].formatted_address;
            this.newAdressForm.controls["address"].setValue(this.address);
            this.newAdressForm.controls["full_info"].setValue(
              results[0].address_components
            );
            this.newAdressForm.controls["place_id"].setValue(
              results[0].place_id || ""
            );
          }
        }
      }
    ); */
  }
  initGeoCoder = () => {
    this.geoCoder = new google.maps.Geocoder();
  };
  dragMarket(event: google.maps.MouseEvent) {
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    this.setAddress();
  }

  onMouseOver(event: google.maps.MouseEvent) {
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    this.setAddress();
  }
  listenForPlaceChange = () => {
    if (this.viewSearchBar) {
      const element = this.searchElementRef.nativeElement;
      let autocomplete = new google.maps.places.Autocomplete(element);
      autocomplete.addListener("place_changed", () => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.geoCodeId = place.place_id;
        this.setAddress();
      });
    }

  };
}
