import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from "src/environments/environment";
import { NotificationService } from "src/app/services/notifications/notification.service";
import { LanguagesService } from "src/app/services/languagesService/languages.service";

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {

  contactForm: FormGroup;
  dynamicForm = [];
  placeHolders = {};
  appId = "";

  contact = [];

  valuesFormContact;

  siteKey : string;
  lang: string;


  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private notiServices: NotificationService,
    private languageService: LanguagesService

  ) {
    this.siteKey = "6LeyXAseAAAAADYzb818QwPFMePfsABURxqvQtIc"
  }


  ngOnInit() {

    this.contactForm = this.formBuilder.group({
      name :new FormControl('', [Validators.required, Validators.minLength(3)]),
      document :new FormControl('', [Validators.required, Validators.minLength(6)]),
      country :new FormControl('', [Validators.required, Validators.minLength(3)]),
      title :new FormControl('', [Validators.required, Validators.minLength(4)]),
      email :new FormControl('', [Validators.required, , Validators.email]),
      phone :new FormControl('', [Validators.required, Validators.minLength(6)]),
      message :new FormControl('', [Validators.required, Validators.minLength(6)]),
      "g-recaptcha-response":new FormControl( '', [Validators.required])
    });


    this.appId = environment.gluky.app_id;
    this.lang = this.languageService.language;
  }

  get getTextsPageContact(){
    return this.languageService.textsIU.page_contact;
  }

  handleSuccess(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit(): void {

    const appId = {
      appId: this.appId
    };

    this.valuesFormContact = this.contactForm.value;
    this.contact = Object.assign(this.valuesFormContact, appId)
    this.modalController.dismiss(this.contact);
    this.notiServices.postContact(this.contact)

  }

  sendForm() {
      this.contactForm.setValue
    }

    closeM() {
      this.modalController.dismiss();
    }

}
