import { CredentialsService } from './../../../services/credentials/credentials.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IProduct } from 'src/app/interfaces/checkout/IProduct';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }
}

@Component({
  selector: 'app-daviplata',
  templateUrl: './daviplata.component.html',
  styleUrls: ['./daviplata.component.scss'],
})
export class DaviplataComponent implements OnInit {
@Input()product : IProduct; 
@Input()adress : any; 
@Input()redemption: boolean = true;
@Input() userInfo;
@Input() points = 0;
@Output() orderStatus: EventEmitter<any> = new EventEmitter();
contactForm: FormGroup;
transferForm: FormGroup;
country:string
quantity = 1;
  constructor(
    private formBuilder : FormBuilder,
    private credentialsService : CredentialsService,
    private ordersService: OrdersService,
    private utilsService : UtilsService ) { 
    this.buildForms();
  }

  ngOnInit() {
    console.log(this.product);
    this.utilsService.getUserPoints().then((result: any) => {
      this.points = result;
      if(this.points < this.product.pointz.raw){
        return this.orderStatus.emit({detail:{ status: 'order_error', infoOrder:null, response: { code: 'INSUFFICIENT_POINTS' } }});
      }
    });

      Object.keys(this.contactForm.controls).forEach((key)=>{
        this.contactForm.get(key).setValue(this.userInfo[key])
    })
  
   
  }

  buildForms(){
    this.contactForm = this.formBuilder.group({
      name:new FormControl('', [Validators.required]),
      lastname :new FormControl('', [Validators.required]),
      email :new FormControl('', [Validators.required,  Validators.email]),
      phone :new FormControl('', [Validators.required, Validators.minLength(10)]),
      phoneAdditional :new FormControl('', [Validators.required, Validators.minLength(10)])
   });
    this.transferForm = new FormGroup({
      phone :new FormControl('', [Validators.required, Validators.minLength(10)]),
      phoneConfirmation :new FormControl('', [Validators.required]),
      dni :new FormControl('', [Validators.required]),
      dniConfirmation :new FormControl('', [Validators.required])
   },
   [CustomValidators.MatchValidator('phone', 'phoneConfirmation'),CustomValidators.MatchValidator('dni', 'dniConfirmation')]);
  }

  onSubmit(){
    this.createOrder();
  }

  credentialsOrders = () => {
    return this.credentialsService.orders();
  };

  createOrder = async () => {
    this.orderStatus.emit({detail:{ status: 'order_loading' }});
 
    const {uid, companyId, programId, appId } = this.credentialsOrders();

    let infoOrder: any = {
      uid,
      sku: this.product.sku.raw,
      quantity: this.quantity,
      companyId,
      programId,
      appId,
      user: {
        username: this.userInfo.username.trim(),
        name: this.contactForm.get('name').value.trim(),
        lastname: (this.contactForm.get('lastname').value) ? this.contactForm.get('lastname').value.trim() : this.userInfo.lastname.trim(),
        docId: this.userInfo.docId,
        email: (this.contactForm.get('email').value).trim().toLowerCase(),
      },
    };

    // Si es de tipo recarga insrta la información en la orden
    if (this.product.type.raw === 'cellphone_credit' || this.product.type.raw === 'reloadable_card') {
      infoOrder = {
        ...infoOrder,
        reloadableCard: {
          accountNumber: this.transferForm.get('phone').value,
          accountIDDoc :this.transferForm.get('dni').value
      }
      };
    } else {
      // Trata la dirección que viene desde google

      infoOrder = {
        ...infoOrder,
        address: {
          ...this.parseAdress(this.adress, this.country),
          contact: {
            ...this.contactForm.value,
          },
        },
      };

      if (!infoOrder.address.contact.email.length) {
        delete infoOrder.address.contact.email;
      }

      if (!infoOrder.user.email.length) {
        delete infoOrder.user.email;
      }

      // if (!infoOrder.address.postalCode.length && this.adress.postalCode) {
      //   infoOrder.address.postalCode = this.adress.postalCode;
      //   console.log('entro en la validacion rara ', infoOrder);
      // }

    }

    try {
      console.log("redemption", this.redemption)
      if (this.redemption) {
        console.log('sale la orden', infoOrder);
        this.ordersService.createOrder(infoOrder)
        .then((data)=>{
          this.resetForm();
          return this.orderStatus.emit({detail:{ status: 'order_success', infoOrder, response: data }});

        }).catch((err)=>{
          console.log(err);
          console.log({detail:{ status: 'order_error', infoOrder, response: err.error }});
          
          return this.orderStatus.emit({detail:{ status: 'order_error', infoOrder, response: err.error }});
          
        })
        
      } else {
        console.log('Information. This redention is not send to Orders. if wish send the transaction, remove the tag redention in the componet - ', infoOrder);
        return this.orderStatus.emit({detail:{ status: 'order_error', infoOrder, response: { code: 'not_redemption' } }});
      }
    } catch (err) {
      console.log('err creación orden', err);
      this.orderStatus.emit({ detail:{ status: 'order_error', infoOrder, response: err.error }});
    }
  };
  resetForm(){
    this.contactForm.reset();
    this.transferForm.reset();
  }

   parseAdress(adressInfo: any, country) {
    if (country === 'co' || country == 'ec'|| country =='cr' || country =='pe' || country =='hn' || country =='ni' || country == 'sv' || country == 'gu') {
      const { addressLine1, addressLine2, locality, adminArea1, adminArea2, country, postalCode, infoExtra } = adressInfo;
      console.log('cuando esta formando el producto', postalCode);
      const finalAdress = {
        addressLine1,
        addressLine2: `${addressLine2}`,
        locality,
        adminArea1,
        adminArea2,
        country,
        placeName: infoExtra,
        postalCode: postalCode.code,
      };
      return finalAdress;
    }
    if (country === 'cl') {
      const { addressLine1, addressLine2, locality, adminArea1, adminArea2, country, postalCode, infoExtra } = adressInfo;
      console.log('cuando esta formando el producto', postalCode);
      const finalAdress = {
        addressLine1,
        addressLine2: `${addressLine2}`,
        locality,
        adminArea1,
        adminArea2,
        country,
        placeName: infoExtra,
        postalCode: '',
      };
      return finalAdress;
    }
    if (country === 'mx') {
      const { addressLine1, addressLine2, locality, adminArea1, adminArea2, country, postalCode, infoExtra } = adressInfo;
      const finalAdress = {
        addressLine1,
        addressLine2: `${addressLine2}`,
        locality, //barrio
        adminArea1, //departamento // estado
        adminArea2, //ciudad // municipuios
        country,
        placeName: infoExtra,
        postalCode: postalCode.code,
      };
      return finalAdress;
    }
  }
}
