import { Component, OnInit, Input } from '@angular/core';
import { LanguagesService } from '../../services/languagesService/languages.service';
import { IPageLoginChat } from '../../interfaces/ILangs';
import { ModalController } from '@ionic/angular';
import { StorageService } from "src/app/services/storage/storage.service";
import { LoaderService } from "src/app/services/loader/loader.service";


import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit {
  LOADER_ID = "profile-detail";
  userForm: FormGroup;
  dynamicForm = [];
  placeHolders = {};
  public myNumberAuth:string;
  public claims:any;

  @Input() values: { editables: []; user: {} };

  constructor(
    private formBuilder: FormBuilder,
    private languagesService: LanguagesService,
    private modalController: ModalController,
    public userService: UserService,
    private storageService: StorageService,
    private loaderService: LoaderService
  ) {
    this.userForm = this.formBuilder.group({});
    
  }

  async ngOnInit() {
    this.fillPlaceHolders();
    this.generateForm();
    this.claims = await this.storageService.get("gnx-claims");
  }
  async generateForm() {
    //this.myNumberAuth = await this.storageService.get("phone-to-register");
    await this.values.editables.map((editable: any) => {
      console.log('editable =',editable);
      if(editable.name=="email"){
        this.userForm.addControl(
          editable.name,
          new FormControl('',[Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] )
        );
      }else{
        this.userForm.addControl(
          editable.name,
          new FormControl('', [Validators.required])
        );
      }
    });
  }
  fillPlaceHolders() {
    const placeholders: any = this.languagesService.textsIU.page_login_chat
      .data;

    this.values.editables.map((editable: any) => {
      placeholders.map((placeholder) => {
        if (editable.id === placeholder.id) {
          editable.placeholder = placeholder.question;
        }
      });
    });
  }



  async sendForm() { 
    const valuesForm = this.userForm.value;
    await this.loaderService.presentLoading(this.LOADER_ID);

      await this.modalController.dismiss(valuesForm);
      await this.loaderService.cancelLoader(this.LOADER_ID);
   

  }
}
