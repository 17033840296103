import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() message
  constructor(private popoverController : PopoverController) { }

  ngOnInit() {
    console.log(this.message);
    
  }

  close(){
    this.popoverController.dismiss()
  }

}
