import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/app';
import { StorageService } from "../storage/storage.service";
import { environment } from "../../../../src/environments/environment";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  uriApiOrder: string = environment.gluky.ordersApiBasePatch;
  api_key: any;

  constructor(
    private http: HttpClient,
    private storageServices: StorageService,
    private authService:AuthService) { }

  async getOrders() {
    const claims = await this.storageServices.get("gnx-claims");
    this.api_key = this.authService.apiKey

    return new Promise((resolve, reject) =>
      this.http
        .get(
          `${this.uriApiOrder}/user-orders/${claims.uid}?key=${this.api_key}`
        )
        .subscribe(
          async (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async getOrderById(orderId:any) {
    const claims = await this.storageServices.get("gnx-claims");
    this.api_key = this.authService.apiKey

    return new Promise((resolve, reject) =>
      this.http
        .get(
          `${this.uriApiOrder}/user-orders/${claims.uid}/${orderId}?key=${this.api_key}`
        )
        .subscribe(
          async (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }

  async createOrder(orderInfo):Promise<any> {
    const claims = await this.storageServices.get("gnx-claims");
    this.api_key = this.authService.apiKey

    return new Promise((resolve, reject) =>
      this.http
        .post(
          `${this.uriApiOrder}/user-orders/${claims.uid}?key=${this.api_key}`,
          JSON.stringify(orderInfo),
        )
        .subscribe(
          async (res) => {
            console.log(res);
            
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        )
    );
  }
  async confirmOrder(order: any) {
    const claims = await this.storageServices.get("gnx-claims");
    const fbToken = await firebase.auth().currentUser.getIdToken(); //firebase token authentication
    this.api_key = this.authService.apiKey;
    const requestOptions = {
      headers: new HttpHeaders({
        'accept': 'application/json',
        'Content-Type': 'application/json'
      })
    };
    const body = {
      'idToken': fbToken
    }
    return new Promise((resolve, reject) =>
      this.http.patch(`${this.uriApiOrder}/user-orders/${claims.uid}/${order.orderId}/${order.deliveryGlobalId}/confirm-received`, JSON.stringify(body), requestOptions)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        })
    );
  }

}
