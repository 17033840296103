import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { fromEvent, merge, of, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {
  onDevice: boolean;
  private online$: Observable<boolean> = undefined;
  //private online$ = new Observable<boolean>;

  constructor(
    public platform: Platform,
    public network: Network) {
      
    this.onDevice = this.platform.is('cordova');
    
       this.online$= Observable.create((observer: { next: (arg0: boolean) => void; }) => {
            observer.next(true);
        }).pipe(mapTo(true));
    

    if (this.platform.is('cordova')) {
        // on Device
        this.online$ = merge(
            this.network.onConnect().pipe(mapTo(true)),
            this.network.onDisconnect().pipe(mapTo(false))
        );
    } else {
        // on Browser
        this.online$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
    }
  }

  public getNetworkType(): string {
      return this.network.type;
  }

  public getNetworkStatus(): Observable<boolean> {
      return this.online$;
  }

  isOnline(): boolean {
    if (this.onDevice && this.network.type) {
      return this.network.type !== 'none';
    } else {
      return navigator.onLine;
    }
  }

  isOffline(): boolean {
    if (this.onDevice && this.network.type) {
      return this.network.type === 'none';
    } else {
      return !navigator.onLine;
    }
  }

  watchOnline(): any {
    return this.network.onConnect();
  }

  watchOffline(): any {
    return this.network.onDisconnect();
  }
}
