// @TODO Guard
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { AuthService } from '../auth/auth.service';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { finalize } from 'rxjs/operators';
import { ConnectivityService } from 'src/app/services/connectivity/connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class GnxAuthGuard implements CanActivate {
  constructor(
    private remoteConfig: RemoteConfigService,
    private router: Router,
    private storageService: StorageService,
    private networkService: ConnectivityService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>((obs) => {
      this.storageService.get('gnx-token').then((res) => {
        if (res) {
          obs.next(false);
          this.networkService
            .getNetworkStatus()
            .subscribe((connected: boolean) => {
              if (connected) {
                return this.router.navigateByUrl('/app/home');
              } else {
                return this.router.navigateByUrl('/app/exhibitions');
              }
            });
        } else obs.next(true);
      });
    });
  }
}
