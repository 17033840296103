import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import jwt_decode from 'jwt-decode';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(public toastController: ToastController) {}
  LOADER_ID = 'exhibition-form';
  async set(key: string, value: any): Promise<void> {
    if (key === 'gnx-token') {
      const decodedToken: any = jwt_decode(value);
      await Storage.set({
        key: 'gnx-claims',
        value: JSON.stringify(decodedToken.gluky),
      });
    }

    try {
      await Storage.set({
        key: key,
        value: JSON.stringify(value),
      });
    } catch (error) {
      console.log('err storage', error);
      let message =
        '¡Error! tu aplicacóin no cuenta con suficiente memoria para almacenar, por favor libera espacio actualizando y vuelve a intentarlo';
      this.presentAlert(message);
    }
  }

  async get(key: string): Promise<any> {
    const item = await Storage.get({ key: key });
    return JSON.parse(item.value);
  }

  async remove(key: string): Promise<void> {
    await Storage.remove({
      key: key,
    });
  }

  async removeAll() {
    await Storage.clear();
  }

  isQuotaExceededError(err: unknown): any {
    return (
      err instanceof DOMException &&
      // everything except Firefox
      (err.code === 22 ||
        // Firefox
        err.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        err.name === 'QuotaExceededError' ||
        // Firefox
        err.name === 'NS_ERROR_DOM_QUOTA_REACHED')
    );
  }

  async presentAlert(message) {
    let actualAlert = await this.toastController.create({
      message,
      position: 'middle',
      duration: 5500,
    });
    actualAlert.present();
  }
}
