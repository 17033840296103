import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { AlertService } from "src/app/services/alert/alert.service";
import { LanguagesService } from '../languagesService/languages.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private storageServices: StorageService,
    private alertService: AlertService,
    public languageService: LanguagesService
  ) { }

  get getTextsPageContact(){
    return this.languageService.textsIU.page_contact;
  }

  async acceptChannels(postData: any) {
    const claims = await this.storageServices.get('gnx-claims');
    const data = {
      user: {
        id: claims.uid,
        appId: claims.app_id,
        username: claims.username,
      },
      channels: {
        sms: {
          accept: postData.sms,
          provider: 'hablame'
        },
        email: {
          accept: postData.email,
          provider: 'mandrill'
        },
        push: {
          accept: postData.push,
          provider: 'clevertap'
        },
      },
    };
    return this.http.put(`${environment.pathNotification}/users/${claims.uid}`, data);
  }

  async getChannelUser() {
    const claims = await this.storageServices.get('gnx-claims');
    return this.http.get(`${environment.pathNotification}/users/${claims.uid}`);
  }

  async postContact(dataU: any) {
    console.log(dataU);
    let res
     this.http
        .post(`${environment.pathNotification}/send/contact`,dataU)
        .subscribe(
          dataR => {
            console.log('contact send', dataR);
            res = dataR
            if (res.success == true) {
              this.alertService.presentAlert(
                this.getTextsPageContact.form.send_contact
              );
              console.log('entro aca ...');
            }else {
              this.alertService.presentAlert(
                this.getTextsPageContact.form.send_error
              );
            }
        },

          err => {console.log("error occurred", err);
            this.alertService.presentAlert(
              `ocurrio un error intentalo mas tarde`
            );
        });
  }
}
