/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { LocalitiesService } from "src/app/services/localities/localities.service";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RouterService } from 'src/app/services/router/router.service';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: "app-map",
  templateUrl: "./map.page.html",
  styleUrls: ["./map.page.scss"],
})
export class MapPage implements OnInit {
  @Input() productModal: string;
  constructor(
    private route: ActivatedRoute,
    private remoteConfig: RemoteConfigService,
    public languageService: LanguagesService,
    private localitiesService: LocalitiesService,
    private routerService: RouterService,
    private alertService:AlertService  ) {}
  public fileCodesFunctions: any;
  public myAdressBottomSheet = "close";
  public newAdressBottomSheet = "close";
  country: string;
  product: any = null;

  ngOnInit() {
    this.getInfoCountry();
    this.route.queryParams.subscribe((params: any) => {
      if (params.data) {
        this.product = JSON.parse(params.data);
      }
    });
  }

  getInfoCountry() {
    this.remoteConfig.getInfoApp().subscribe((info) => {
      this.country = info.country;
      // this.openModalListAdress();
    });
  }

  get getTextIUAddress() {
    return this.languageService.textsIU.page_maps;
  }

  goToAddNewAdress() {
    this.alertService.dismissPopup();
    console.log("goToAddNewAdress");
    this.routerService.redirect(`app/map/add-address`);
  }

  async checkCountry() {
    this.fileCodesFunctions = await this.localitiesService.getFileByCountry();
    this.country = this.localitiesService.country;
  }
  // async openModalListAdress() {
  //   const modal = await this.modalController.create({
  //     component: ListAddressComponent,
  //     cssClass: "my-custom-class gnx-modal",
  //     id: "gnx-list-address",
  //     componentProps: {
  //       productModal: this.productModal
  //     }
  //   });
  //   await modal.present();
  //   modal.onDidDismiss().then((res) => {
  //     this.modalController.dismiss(null, null, "modalConfig");
  //     if (res.data.type === "newAddress") {
  //       this.openModalToNewAddress(res.data.address);
  //     } else {
  //       this.alertService.dismissPopup();
  //       if (this.platformService.checkPlatform("desktop")){
  //         this.alertService.dismissPopup();
  //       }
  //     }
  //   });
  // }

  // async openModalToNewAddress(address?) {
  //   const modal = await this.modalController.create({
  //     component: AddAddressComponent,
  //     cssClass: "my-custom-class gnx-modal",
  //     backdropDismiss: false,
  //     componentProps: {
  //       country: this.country,
  //       address
  //     },
  //   });
  //   await modal.present();
  //   modal.onDidDismiss().then((res) => {
  //
  //   });
  // }
}
