import { Injectable } from '@angular/core';
import { CredentialsService } from '../credentials/credentials.service';

@Injectable({
  providedIn: 'root'
})
export class ElasticService {
  baseUrl: string;
  engineName: string;
  searchKey: string;

  constructor(private credentialsService: CredentialsService) {
  }

  async getAllProducts(search) {
    try {
      return await this.Query("POST", "search", search);
    } catch (err) {
      throw new Error(err);
    }
  }
  async getAllCategories() {
    const params = {
      query: "",
      page: {
        size: 1,
        current: 1,
      },
      facets: {
        category1: { type: "value", size: 100 },
        category2: { type: "value", size: 100 },
      },
    };
    try {
      const response = await this.Query("POST", "search", params);
      console.log("response", response);
      if (response.facets) {
        return response.facets;
      }
    } catch (err) {
      throw new Error(err);
    }
  }
  async getSubCategories(category) {
    const params = {
      query: "",
      filters: {
        "category1": [category]
      },
      page: {
        size: 1,
        current: 1,
      },
      facets: {
        category2: { type: "value", size: 100 }
      },
    };
    try {
      const response = await this.Query("POST", "search", params);
      console.log("response", response);
      if (response.facets) {
        return response.facets;
      }
    } catch (err) {
      throw new Error(err);
    }
  }
  async getProductById(product) {
    const params = {
      query: product,
      page: {
        size: 1,
        current: 1,
      },
      search_fields: {
        id: {},
      },
    };

    const response = await this.Query("POST", "search", params);
    return response;
  }
  async Query(method, url_param, params) {
    const { base_url, engine_name, search_key } = await this.credentialsService.catalogueNew();
    const initialUrl = `${base_url}/${engine_name}`;
    const urlComplete = `${initialUrl}/${url_param}`;
    const body = params;
    const fetch_params = {
      method: method,
      headers: new Headers({
        Authorization: `Bearer ${search_key}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(body),
    };

    const query = await fetch(urlComplete, fetch_params);
    return await query.json();
  }
}
